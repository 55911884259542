'use strict';

$(document).ready(function () {
    var tablist = $('[role="tablist"]');
    var tabContent = $('.tab-content:visible');

    var tabs = [];
    var panels = [];

    // For easy reference
    var keys = {
        left: 37,
        right: 39
    };

    // Add or subtract depending on key pressed
    var direction = {
        37: -1,
        39: 1,
    };

    for (var i = 0; i < tablist.length; ++i) {
        tabs[i] = tablist[i].querySelectorAll('[role="tab"]');
        panels[i] = tabContent[i].querySelectorAll('[role="tabpanel"]');
        // Bind listeners
        for (var j = 0; j < tabs[i].length; ++j) {
            addListeners(tabs[i], i, j);
        }
    }

    function addListeners(tabs, tabListIndex, index) {
        tabs[index].tabListIndex = tabListIndex;
        tabs[index].addEventListener('click', clickEventListener, false);
        tabs[index].addEventListener('keyup', keyupEventListener, false);
        // Build an array with all tabs (<button>s) in it
        tabs[index].index = index;
    }

    // When a tab is clicked, activateTab is fired to activate it
    function clickEventListener(event) {
        activateTab(event.target, event.target.tabListIndex);
    }

    // Handle keyup on tabs
    function keyupEventListener(event) {
        var key = event.keyCode;

        if(key === keys.left || key === keys.right) {
            switchTabOnArrowPress(event);
        }
    }

    // Either focus the next, previous, first, or last tab
    // depending on key pressed
    function switchTabOnArrowPress(event) {
        var pressed = event.keyCode;

        if (direction[pressed]) {
            var target = event.target;
            var tabListIndex = target.tabListIndex;
            var currentTabs = tabs[tabListIndex];
            var tabToActivate = currentTabs[0];
            if (target.index !== undefined) {
                if (tabs[tabListIndex][target.index + direction[pressed]]) {
                    tabToActivate = currentTabs[target.index + direction[pressed]];
                } else if (pressed === keys.left || pressed === keys.up) {
                    tabToActivate = currentTabs[currentTabs.length - 1];
                }
                activateTab(tabToActivate, tabListIndex);
            }
        }
    }

    // Activates any given tab panel
    function activateTab(tab, tabListIndex) {
        if(typeof tabListIndex != 'undefined') {
            // Deactivate all other tabs
            deactivateTabs(tabListIndex);
    
            // Remove tabindex attribute
            tab.setAttribute('tabindex', '0');
    
            // Set the tab as selected
            tab.setAttribute('aria-selected', 'true');
    
            // Get the value of aria-controls (which is an ID)
            var controls = tab.getAttribute('aria-controls');
    
            // Add active class
            tab.classList.add('active');
            // Add active class from tab panel to make it visible
            $('#'+controls).addClass('active');
            $('.'+controls).addClass('active');
            // Add focus tab
            tab.focus();
    
            // Trigger click event
            document.getElementById(tab.id).click();
        }
    }

    // Deactivate all tabs and tab panels
    function deactivateTabs(tabListIndex) {
        var currentTabs = tabs[tabListIndex];
        var currentPanels = panels[tabListIndex];
        for (var t = 0; t < currentTabs.length; t++) {
            currentTabs[t].setAttribute('tabindex', '-1');
            currentTabs[t].setAttribute('aria-selected', 'false');
            currentTabs[t].classList.remove('active');
        }

        for (var p = 0; p < currentPanels.length; p++) {
            currentPanels[p].classList.remove('active');
        }
    }
});