'use strict';

$('.all-tab-gallery-carousel').owlCarousel({
    items: 4,
    center: false,
    loop: false,
    nav: true,
    touchDrag: true,
    dots: false,
    itemElement: "li",
    stageElement: "ul",
    navText: [
        '<span class="gallery-prev-arrow"><span class="icon-CHEVRON"></span></span>',
        '<span class="gallery-next-arrow"><span class="icon-CHEVRON"></span></span>'
    ],
    onInitialized: () => {
        $('.owl-item').attr('role', 'list');
    },
    responsive: {
        0: {
            items: 1,
            center: false,
            touchDrag: true,
            nav: true,
            dots: false,
        },
        768: {
            items: 3,
            center: false,
            nav: false,
            dots: false,
        },
        1024: {
            items: 4,
            center: false,
            nav: true,
            dots: false,
        }
    }
});


$('.mobile-gallery-tabs').slick({
    slidesToShow: 3.5,
    centerPadding: '0',
    arrows: false,
    swipe: true,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3.5,
                initialSlide: 0,
                centerMode: false,
                arrows: false,
                swipe: true,
                infinite: false,
                variableWidth: true,
                focusOnSelect: true
            }
        },
    ],
});

$('.mobile-tab-panel-gallery').slick({
    arrows: true,
    swipe: true,
    infinite: false,
    initialSlide: 0,
    swipeToSlide: true,
    autoplay: false,
    nextArrow: '<button class="next-arrow"><span class="icon-CHEVRON"></span></a>',
    prevArrow: '<button class="prev-arrow"><span class="icon-CHEVRON"></span></a>',
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerMode: false,
                initialSlide: 0,
                arrows: true,
                swipe: true
            }
        },
    ],
});

$('.navigationLinks-gallery').on('click', function () {
    if (!$(this).hasClass('active')) {
        var currentTab = $(this).attr('aria-controls');
        $('.gallery-container .nav-item').removeClass('active').attr({'aria-selected' : 'false', 'tabindex' : -1});
        $(this).addClass('active').attr({'aria-selected' : 'true', 'tabindex' : 0}).focus();
        $('.gallery-container .tab-content').find('.tabPanel ').removeClass('active');
        $('.gallery-container .tab-content').find('#' + currentTab).addClass('active');
        $('.gallery-container .tab-content').find('#' + currentTab).find('.all-tab-gallery-carousel').slick('refresh');
        $('.mobile-tab-panel-gallery').slick('refresh');
    }
});

$(document).ready(function () {
    var prevArrowData = $('.prev-arrow-text').attr('data-text');
    $('.owl-prev').attr('aria-label', prevArrowData);
    var nextArrowData = $('.next-arrow-text').attr('data-text');
    $('.owl-next').attr('aria-label', nextArrowData);
});